import React from 'react';
import './RewardsBody.css';
import EmailForm from '../../components/EmailForm/EmailForm';
import useMediaQuery from './../../hooks/useMediaQuery';

const RewardsBody = ({
  title,
  headline,
  picURL,
  backgroundPic1,
  backgroundPic2,
  textColorDesktop,
  textColorMobile,
  extraHeader,
  campaignName
}) => {
  const isUnder800 = useMediaQuery('(max-width: 800px)');

  return (
    <div className='rewards-body-container-main'>
      {' '}
      {extraHeader ? (
        <div className="optional-header"> Get an extra $750 after you submit a product review!</div>
      ) : (
        <></>
      )}
      <div className="rewards-body-container">
        <div
          className="reward-body-item"
          id="body-photo-rewards"
          style={{ backgroundImage: `url(${backgroundPic1})` }}
        ></div>
        <div
          className="reward-body-item"
          style={{ backgroundImage: isUnder800 ? `url(${backgroundPic2})` : '' }}
        >
          {/* <h2 className="rewards-title-text">{title}</h2> */}
          <h2
            className="rewards-headline-text"
            style={{ color: isUnder800 ? textColorMobile : textColorDesktop }}
          >
            You Qualify to Get {headline}
          </h2>
          {/* <small className="">Complete twenty Deals to claim*</small> */}
          <img id="promo-image" src={picURL} /> <br />
          <br />
          <div>
            <EmailForm picURL={picURL} pageType="rewards" campaignName={campaignName} />
            <div className="disclaimer3">
              *UPON COMPLETION OF PURCHASE & PROGRAM REQUIREMENTS. Trade names or rights associated
              with all brands on this site are the property of their respective owners and are not
              affiliated with this promotion.
            </div>
          </div>
          <br />
        </div>

        <div className="rewards-additional-info"></div>
      </div>
    </div>
  );
};

export default RewardsBody;

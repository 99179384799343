import React, { useState, useEffect, useCallback } from 'react';
import { BINOM_URL } from '../../constants';
import './UserForm.css';
import { validateBirthday, statesList } from './validation';
import Loader from '../Loader/Loader';
import Header from '../Header/Header';

const UserForm = () => {
  const params = new URLSearchParams(window?.location?.search);
  const [loading, setLoading] = useState(null);
  const [success, setSuccess] = useState(false);
  const promoImage = params?.get('promoimg');
  const subID = params?.get('sub1');
  const campaignName = params?.get('campaignName');
  const clickID = params?.get('clickid');
  const inputElement = React.useRef();

  const [formData, setFormData] = useState({
    email: params?.get('email') || 'test@gmail.com',
    firstName: '',
    lastName: '',
    day: '',
    month: '',
    year: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    gender: 'Other',
  });

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  });

  const cleanErrors = () => {
    setErrors({
      firstName: '',
      lastName: '',
      dob: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: '',
    });
  };
  const [attempts, setAttempts] = useState({
    firstName: 0,
    lastName: 0,
    dob: 0,
    phone: 0,
    address: 0,
    city: 0,
    state: 0,
    zip: 0,
  });

  const areErrorsEmpty = () => {
    const errorArray = Object.entries(errors);
    return errorArray.every((el) => el[1] === '');
  };
  const areAttemptsEmpty = () => {
    const attemptArray = Object.entries(attempts);
    return attemptArray.every((el) => el[1] === '');
  };

  const isFormDataFull = () => {
    const formDataArray = Object.entries(formData);
    return formDataArray.every((el) => el[1] !== '');
  };

  useEffect(() => {
    if (areErrorsEmpty() && isFormDataFull()) {
      setSuccess(true);
    } else {
      setSuccess(false);
    }
  }, [formData, errors, attempts]);

  const handleSubmit = (e) => {
    e.preventDefault();
    validate();

    if (areErrorsEmpty() && isFormDataFull()) {
      setLoading(true);
      const doLoading = setTimeout(() => {
        // Also woulb be great to send a Welcome Email to the User and to show some offers to buy stg

        //use this to redirect to advertizer (if using above first move this to the UserForm file and pass all the params)



        window.location.replace(
          `${BINOM_URL}?sub1=${subID}&sub2=${formData.email}&email=${formData.email}&sub3=${formData.firstName}&sub4=${formData.lastName}&sub5=${formData.address}&sub6=${formData.city}&sub7=${formData.state}&sub8=${formData.zip}&sub9=${formData.phone}&sub10=${formData.day}&sub11=${formData.month}&sub12=${formData.year}`
        );
      }, 200);

      // can use a Math Random fn to generate % chance of user going to offer or being redirected to stg else
    }
  };

  const validate = () => {
    if (formData.firstName.length < 2 && attempts.firstName == true) {
      setErrors((prev) => {
        return { ...prev, firstName: 'First name must be at least 2 characters long' };
      });
    } else {
      setErrors((prev) => {
        return { ...prev, firstName: '' };
      });
    }

    if (formData.lastName.length < 2 && attempts.lastName == true) {
      setErrors((prev) => {
        return { ...prev, lastName: 'Last name must be at least 2 characters long' };
      });
    } else {
      setErrors((prev) => {
        return { ...prev, lastName: '' };
      });
    }

    if (formData.address.length < 5 && attempts.address == true) {
      setErrors((prev) => {
        return { ...prev, address: 'Address must be at least 5 characters long' };
      });
    } else {
      setErrors((prev) => {
        return { ...prev, address: '' };
      });
    }

    if (formData.zip.length !== 5 && attempts.zip == true) {
      setErrors((prev) => {
        return { ...prev, zip: 'Zip code must be 5 characters long' };
      });
    } else {
      setErrors((prev) => {
        return { ...prev, zip: '' };
      });
    }

    if (formData.state.length !== 2 && attempts.state == true) {
      setErrors((prev) => {
        return { ...prev, state: 'Please select your State' };
      });
    } else {
      setErrors((prev) => {
        return { ...prev, state: '' };
      });
    }

    if (formData.city.length < 3 && attempts.city == true) {
      setErrors((prev) => {
        return { ...prev, city: 'City name must be at least 3 characters long' };
      });
    } else {
      setErrors((prev) => {
        return { ...prev, city: '' };
      });
    }
    const PhoneRegex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

    if ((formData.phone.length < 7 || PhoneRegex.test(formData.phone)) && attempts.phone == true) {
      setErrors((prev) => {
        return { ...prev, phone: 'Please enter a valid phone number' };
      });
    } else {
      setErrors((prev) => {
        return { ...prev, phone: '' };
      });
    }

    if (
      !validateBirthday(`${formData.year}-${formData.month}-${formData.day}`) &&
      attempts.dob == true
    ) {
      setErrors((prev) => {
        return { ...prev, dob: 'Please enter a valid DOB' };
      });
    } else {
      setErrors((prev) => {
        return { ...prev, dob: '' };
      });
    }

    // if (formData.gender.length < 3) {
    //   setErrors((prev) => {
    //     return { ...prev, gender: 'Select Your Gender' };
    //   });
    // } else {
    //   setErrors((prev) => {
    //     return { ...prev, gender: '' };
    //   });
    // }
  };

  const handleChange = (e) => {
    validate();
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setAttempts({ ...attempts, [name]: true });
  };

  const handleBlur = (e) => {
    validate();
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const generateYearOptions = () => {
    const arr = [];

    const startYear = 1930;
    const endYear = new Date().getFullYear() - 18;

    for (let i = endYear; i >= startYear; i--) {
      arr.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return arr;
  };

  const generateDayOptions = () => {
    const arr = [];
    for (let i = 1; i <= 31; i++) {
      if (i < 10) {
        arr.push(
          <option key={'0' + i} value={'0' + i}>
            0{i}
          </option>
        );
      } else {
        arr.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
    }
    return arr;
  };

  const handleEmptySubmit = (e) => {
    e.preventDefault();
    setAttempts({
      ...attempts,
      firstName: 1,
      lastName: 1,
      dob: 1,
      phone: 1,
      address: 1,
      city: 1,
      state: 1,
      zip: 1,
    });
    if (
      formData.firstName === '' ||
      formData.lastName === '' ||
      formData.dob === '' ||
      formData.phone === '' ||
      formData.address === '' ||
      formData.city === '' ||
      formData.state === '' ||
      formData.zip === ''
    ) {
      validate();
    }
  };

  if (loading) {
    return (
      <div className="loader-div">
        <h2 id="loading-text">Loading your Reward...</h2>
        <br />
        <br />
        <Loader width="15.5em" height="12.23em" style={{ border: '1px solid black' }} />
      </div>
    );
  }

  return (
    <div>
      <div className="user-form-header"> Registration Required </div>
      <br />
      <img id="promo-image" src={promoImage} />
      <div>
        <h5>Please fill out the basics</h5>
      </div>
      <div id="rwrd-availabl"> #rewardavailable</div>
      <form className="data-flow-form">
        <div>
          <label>Your Name</label>
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p>{errors.firstName}</p>
        </div>
        <div>
          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p>{errors.lastName}</p>
        </div>

        <div>
          <label>Your Shipping Address</label>
          <input
            type="text"
            placeholder="Street Address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p>{errors.address}</p>
        </div>

        <div>
          <input
            type="number"
            placeholder="Zip Code"
            name="zip"
            value={formData.zip}
            onChange={handleChange}
            onBlur={handleBlur}
            onClick={handleChange}
          />
          <p>{errors.zip}</p>
          <select
            type="text"
            placeholder="State"
            name="state"
            value={formData.state}
            onChange={handleChange}
            onBlur={handleBlur}
            onClick={handleChange}
          >
            <option value="0" key={0}>
              State
            </option>
            {statesList.map((s) => {
              return (
                <option value={s.abbreviation} key={s.abbreviation}>
                  {s.abbreviation}
                </option>
              );
            })}
          </select>
          <p>{errors.state}</p>
        </div>

        <div>
          <input
            type="text"
            placeholder="City/Town"
            name="city"
            value={formData.city}
            onChange={handleChange}
            onBlur={handleBlur}
            onClick={handleChange}
          />
          <p>{errors.city}</p>
        </div>

        <div>
          <label>Last few details:</label>
          <input
            type="number"
            placeholder="Mobile Phone #"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            onClick={handleChange}
          />
          <p>{errors.phone}</p>
        </div>

        <div>
          <small>Date of Birth:</small>
          <div id="DOB-form">
            <select
              className="DOB-item"
              type="dropdown"
              placeholder="Month"
              name="month"
              value={formData.month}
              onChange={handleChange}
              onBlur={handleBlur}
              onClick={handleChange}
            >
              <option key={1} value="0">
                Month
              </option>
              <option key={2} value="01">
                Jan
              </option>
              <option key={3} value="02">
                Feb
              </option>
              <option key={4} value="03">
                Mar
              </option>
              <option key={5} value="04">
                Apr
              </option>
              <option key={6} value="05">
                May
              </option>
              <option key={7} value="06">
                Jun
              </option>
              <option key={8} value="07">
                Jul
              </option>
              <option key={9} value="08">
                Aug
              </option>
              <option key={10} value="09">
                Sept
              </option>
              <option key={11} value="10">
                Oct
              </option>
              <option key={12} value="11">
                Nov
              </option>
              <option key={13} value="12">
                Dec
              </option>
            </select>
            <select
              className="DOB-item"
              type="text"
              placeholder="Day"
              name="day"
              value={formData.day}
              onChange={handleChange}
              onBlur={handleBlur}
              onClick={handleChange}
            >
              <option value="0" key={0}>
                Day
              </option>
              {generateDayOptions()}
            </select>
            <select
              className="DOB-item"
              type="text"
              placeholder="Year"
              name="year"
              value={formData.year}
              onChange={handleChange}
              onBlur={handleBlur}
              onClick={handleChange}
            >
              <option value="y" key={0}>
                Year
              </option>
              {generateYearOptions()}
            </select>
          </div>

          <p>{errors.dob}</p>
        </div>

        <div>
          <small>Select Gender</small>
          <div id="Gender-form">
            <input
              className="Gender-item"
              type="button"
              name="gender"
              value="Male"
              onClick={handleChange}
              onBlur={handleBlur}
              onSelect={handleChange}
            />
            <input
              className="Gender-item"
              type="button"
              name="gender"
              value="Female"
              onClick={handleChange}
              onBlur={handleBlur}
            />
            <input
              className="Gender-item"
              type="button"
              name="gender"
              value="Other"
              onClick={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <p>{errors.gender}</p>
        </div>

        {success ? (
          <button
            id="user-form-btn-sbmit"
            style={{ opacity: success ? '100%' : '50%' }}
            onClick={handleSubmit}
          >
            {' '}
            Continue 🎁
          </button>
        ) : (
          <button
            id="user-form-btn-sbmit"
            style={{ opacity: success ? '100%' : '50%' }}
            onClick={handleEmptySubmit}
          >
            {' '}
            Continue 🎁
          </button>
        )}
      </form>
    </div>
  );
};

export default UserForm;

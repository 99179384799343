import React from 'react';
import * as SVGLoaders from 'svg-loaders-react';

const Loader = ({ width = '5.5em', height = '2.23em', color = 'rgb(56, 209, 107)' }) => {
  return (
    <div>
      <SVGLoaders.Oval
        className='absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2'
        width={width}
        height={height}
        stroke={color}
        strokeWidth='10px' 
      />
    </div>
  );
};

export default Loader;

import React from 'react';
import './MoreInfo.css'
import Rewards from './../Rewards';

const MoreInfo = () => {
  return (
    <div className='more-info-container'>
      <div>
        <h2>How fast can I get my Reward?</h2>
      </div>
      <div>
        You can typically complete all of the required sponsored Deals within 5-7 days (some may
        take up to 60 days to complete). Once you complete the required number of Deals, you must
        complete the Incentive claims process, which requires ID verification. It typically takes
        our customer service team 5-7 days to verify and deliver your Incentive.
      </div>
    </div>
  );
};

export default MoreInfo;

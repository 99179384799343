import React, { useState } from 'react';
import './Header.css';
import Logo from '../../assets/Rew&s.png';
import { Link } from 'react-router-dom';

const Header = ({ backcolor = `rgb(56, 209, 107)`, pageType = 'rewards' }) => {

  return (
    <div
      className="sr-header-container"
      style={{ background: `linear-gradient(to right, ${backcolor}, #FFD700)` }}
    >
      <div className="header-left-item">
        <img className="header-logo1" src={Logo}></img>
      </div>
      <div className="header-right-item">
        {' '}
        <Link to={() => '/termsandconditions'} target="_blank" rel="noopener noreferrer">
          {' '}
          TERMS OF USE
        </Link>{' '}
      </div>
    </div>
  );
};

export default Header;

import React, { useState } from 'react';
import './HeaderSamples.css';
import Logo from '../../assets/Rew&s.png';
import checkbox from '../../assets/check-circle.png';
import Samples from './../../Samples/Samples';
import { Link } from 'react-router-dom';


const HeaderSamples = ({ pageType, title, headline }) => {

  return (
    <div className="samples-header-container">
      <div className="header-left-item">
        <img className="header-logo2" src={Logo}></img>
        <span id="brand-text">REWARDS & SAMPLES</span>
      </div>

      <div id="text-1">
        <span>
          {' '}
          <img className="header-checkbox" src={checkbox}></img> {headline}
        </span>
      </div>
      <div id="text-2">
        <span>{title}</span>
      </div>
      <div className="header-right-item1">
        {' '}
        <Link to={() => '/termsandconditions'} target="_blank" rel="noopener noreferrer">
          {' '}
          TERMS OF USE
        </Link>{' '}
      </div>
    </div>
  );
};

export default HeaderSamples;

import React from 'react';
import './SamplesBody.css';
import EmailForm from '../../components/EmailForm/EmailForm';
import useMediaQuery from './../../hooks/useMediaQuery';

const SamplesBody = ({ title, headline, picURL, backgroundPic1, backgroundPic2 }) => {
  const isMobile = useMediaQuery('(max-width: 800px)');

  return (
    <div className="samples-body-container">
      <div
        className="sample-body-item"
        id="body-photo-samples"
        style={{ backgroundImage: `url(${backgroundPic1})` }}
      ></div>
      <div
        className="sample-body-item"
        style={{ backgroundImage: isMobile ? `url(${backgroundPic2})` : '' }}
      >
        {/* <small className="">Complete twenty Deals to claim*</small> */}
        <h1 className="headline-text">{headline}</h1>
        <h1 className="title-text">{title}</h1>
        <img id="promo-image-samples" src={picURL} /> <br />
        <br />
        <div>
          <EmailForm picURL={picURL} pageType="samples" />
          <div
            className="disclaimer2"
            style={{ color: backgroundPic2 === 'none' && isMobile ? 'grey' : '' }}
          >
            *UPON COMPLETION OF PURCHASE & PROGRAM REQUIREMENTS. Trade names or rights associated
            with all brands on this site are the property of their respective owners and are not
            affiliated with this promotion.
          </div>
        </div>
        <br />
      </div>

      <div className="samples-additional-info"></div>
    </div>
  );
};

export default SamplesBody;

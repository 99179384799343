import React, { useState, useEffect } from 'react';
import successIcon from '../../assets/success-35.png';
import './EmailForm.css';
import { Button } from 'react-bootstrap';
import {  BINOM_URL, EMAIL_VERIFICATION_URL } from './../../constants';
import Loader from '../Loader/Loader';
import { Link, NavLink, useHistory } from 'react-router-dom';
import Questions from '../Questions/Questions';

const EmailForm = ({ color = 'black', picURL, pageType, campaignName }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(null);
  const [success2, setSuccess2] = useState(null);
  const [clickId, setClickID] = useState('1234');
  const [questionsAnswered, setQuestionAnswered] = useState([1]);


  const history = useHistory();

  useEffect(() => {

    const params = new URLSearchParams(window?.location?.search);
    const subID = params?.get('subid');
    const cid = params?.get('clickid');
    const splitSUBIDsArr = String(subID)?.split(/_|-/)
    const subIDtoDash = splitSUBIDsArr?.join('-')

    console.log(subID);
    console.log(cid);


    // below is an example of Splitting SubSources by _ and - to report granularly to advertiser
    const splitSUBIDsArrEX = String('42_877_177760-51-125').split(/_|-/)
    // console.log(splitSUBIDsArrEX)

    const s1 = splitSUBIDsArrEX[0]
    const s3 = splitSUBIDsArrEX[1]
    const s4 = splitSUBIDsArrEX[2]
    const s5 = splitSUBIDsArrEX[3]
    const s6 = splitSUBIDsArrEX[4]

    // console.log(s1)
    // console.log(s3)
    // console.log(s4)
    // console.log(s5)
    // console.log(s6)


    // console.log(params?.get('rtkcid'))
    // console.log(params?.get('customcid')) this gets Affise's clickid

  }, []);

  const isValidEmail = (email) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email);
  };

  const handleChange = async (event) => {
    setEmail(event.target.value);

    if (
      !isValidEmail(event.target.value) &&
      event.target.value?.length > 1 &&
      event.type === 'blur'
    ) {
      setError('Please enter an email with a valid format');
      setSuccess(null);
    } else if (
      !isValidEmail(event.target.value) &&
      event.target.value?.length < 2 &&
      event.type === 'blur'
    ) {
      setError('Please enter full email');
      setSuccess(null);
    } else if (isValidEmail(event.target.value)) {
      setError(null);
      setSuccess(true);
    }

    setEmail(event.target.value);
  };

  const handleEmptySubmit = () => {
    if (email === '') {
      setError('Please enter your email first');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    const isRealEmail = () => {
      const isRealEmailViaAPI = async () => {
        return fetch(`${EMAIL_VERIFICATION_URL}&email=${email}`)
          .then((res) => res.json())
          .then((data) => {
            if (
              !data?.is_smtp_valid.value ||
              !data?.is_mx_found.value ||
              data?.is_catchall_email.value
            ) {
              setError(`Please enter a real email that exists! 
              You will need to access this email in order to receive your reward`);
              setSuccess(null);
              setSuccess2(false);
              setLoading(false);
              return;
            } else {
              setError(null);

              setSuccess(true);
              setSuccess2(true);
              setLoading(true);

              window.localStorage.setItem('S&R', `${email}`);

              // const params = new URLSearchParams(window?.location?.search)
              // console.log(params?.get('customcid'))

              //THIS FIRES POSTBACK TO TRACKING PLATFORM
              //
              // fetch(
              //   `https://0fopi.rdtk.io/postback?clickid=${params?.get('rtkcid')}&ptoken=3khgqf8kb8`
              // );

              const doLoading = setTimeout(() => {
                const params = new URLSearchParams(window?.location?.search);
                const subID = params?.get('subid');
                const cid = params?.get('clickid');
                const splitSUBIDsArr = String(subID)?.split(/_|-/)
                const subIDtoDash = splitSUBIDsArr?.join('-')







                window.location.replace(
                  `${BINOM_URL}&event1=${email}`
                );





                // use below to redirect user to share more info
                // history.push(`/dataflowpage?email=${email}&clickid=${cid}sub1=${subIDtoDash}&promoimg=${picURL}&campaignName=${campaignName}`);
                // Also woulb be great to send a Welcome Email to the User and to show some offers to buy stg

                //use this to redirect to advertizer (if using above first move this to the UserForm file and pass all the params)
                // window.location.replace(
                //   `${REDTRK_URL}?sub2=${email}&email=${email}`
                // );
              }, 200);
            }
          });
      };

      return isRealEmailViaAPI();
    };

    // Option to not make API call to Abstract API for email verification if there is no clickID
    // const params = new URLSearchParams(window?.location?.search);
    // setClickID(params?.get('rtkcid'));
    // if (clickId === null) {
    //   return
    // } 
    

    await isRealEmail();
  };

  const buttonType = (pageType) => {
    if (pageType === 'samples' && !success) {
      return (
        <button
          className="samples-letsgo-btn"
          onClick={handleEmptySubmit}
          style={{ opacity: success ? '100%' : '85%' }}
        >
          LET'S GO! 🎉
        </button>
      );
    } else if (pageType === 'samples' && success) {
      return (
        <button className="samples-letsgo-btn" onClick={handleSubmit}>
          LET'S GO! 🎉
        </button>
      );
    } else if (pageType === 'rewards' && !success) {
      return (
        <button
          className="rewards-btn-getit"
          onClick={handleEmptySubmit}
          style={{ opacity: success ? '100%' : '85%' }}
        >
          CONTINUE 👉
        </button>
      );
    } else if (pageType === 'rewards' && success) {
      return (
        <button className="rewards-btn-getit" onClick={handleSubmit}>
          CONTINUE 👉
        </button>
      );
    } else if (pageType === 'toprewards' && !success) {
      return (
        <button
          className="toprewards-btn-getit"
          onClick={handleEmptySubmit}
          style={{ opacity: success ? '100%' : '85%' }}
        >
          CONTINUE 👉
        </button>
      );
    } else if (pageType === 'toprewards' && success) {
      return (
        <button className="toprewards-btn-getit" onClick={handleSubmit}>
          CONTINUE 👉
        </button>
      );
    }
  };

  return (
    <div>
      <div className="whole-form">
        {questionsAnswered.length === 2 && pageType !== 'samples' ? (
          <Questions
            questionsAnswered={questionsAnswered}
            setQuestionAnswered={setQuestionAnswered}
          />
        ) : (
          <div>
            <div className={success ? 'email-form-container-success' : 'email-form-container'}>
              <form autoComplete="on" className="email-form">
                <label className="email-label" htmlFor="email">
                  Enter your Email <span className="not-success"></span>
                </label>
                <div>
                  <input
                    id="email-input"
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                  {success2 && <img className="success-icon" src={successIcon}></img>}
                  {!success2 && <span className="not-success"></span>}
                  <br />
                  {error && (
                    <small style={{ color: '#B22222' }}>
                      {error}
                      <span className="not-success"></span>
                    </small>
                  )}
                  <br />{' '}
                </div>
              </form>
            </div>
            <br />
            {!success ? (
              <div>{buttonType(pageType)}</div>
            ) : (
              <div>{loading ? <Loader /> : <> {buttonType(pageType)}</>}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailForm;

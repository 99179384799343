import React from 'react';
import './Terms.css';

const PrivacyPolicy = () => {
  return (
    <div>
      {' '}
      Rewards & Samples, LLC Websites
      <h3>Privacy Policy </h3>
      <div className="terms-main">
        <p>
          {' '}
          Rewards & Samples, LLC (“we,” “our,” or “us”) provides the following Privacy Policy
          ("Privacy Policy” or “Policy"), which sets forth our practices related to collecting,
          using, disclosing, and selling information that is personally-identifiable to a user
          “Information.” Capitalized terms used but not defined herein shall have the same meaning
          as in the Website’s Terms and Conditions, which are fully incorporated herein by
          reference.{' '}
        </p>

        <p>
          ACCEPTANCE OF PRIVACY POLICY By accessing and using one of our websites that we own and
          operate which offer rewards and/or other content (collectively, “Rewards”) to users
          (collectively, the “Websites” and individually, a “Website, you signify your acceptance of
          this Privacy Policy and your agreement to the collection, use, disclosure, and sale of
          your Information as described herein. If you do not intend to agree to the terms of this
          Privacy Policy, DO NOT use the Website. As set out below, the Privacy Policy is subject to
          change. Your continued use of the Website following the posting of changes to these terms
          will confirm your acceptance of all changes made except for changers which expand the use
          of your Information where we will notify users by: (a) sending the modified Policy to our
          users via email or (b) any other reasonable means acceptable under applicable state and
          federal law. Please note if you are not a U.S. resident, you are not permitted to use the
          Website and/or otherwise access any other of our offerings.{' '}
        </p>

        <p>
          NOTICE OF COLLECTION OF PERSONAL INFORMATION This Policy provides notice to users,
          including those who are California residents and fall within the definition of “consumers”
          as provided for in the California Consumer Privacy Act of 2018 (“CCPA”), that we collect
          Information. As required by the CCPA, the following sections of the Policy also detail the
          categories and sources of Information collected and the business and commercial purposes
          for the collected Information. We also include a description of rights afforded to
          California consumers at “RIGHTS OF CALIFORNIA USERS WITH REGARD TO THEIR INFORMATION”,
          including rights to know the Information we have collected and sold, the right to opt-out
          from the sale of Information, and the right to deletion.{' '}
        </p>

        <p>
          TYPES/CATEGORIES OF INFORMATION WE MAY COLLECT REGARDING USERS Here are the types and
          categories of Information that we may collect regarding users (see below for additional
          descriptions of the categories of Information collected that are provided to California
          residents): Information that could reasonably identify a user, such as name, postal
          address, email address(es), mobile/landline phone number(s), date of birth, and gender;
          Information collected from a user’s responses to survey questions, including preferences,
          products and services of interest, personal attributes such as marital status, home or
          auto ownership, political affiliation, views on various issues, and responses to wellness
          questions, as further described below; Information collected from our web server log
          files, including device type(s), user agent data, referring URL(s), and IP address(es);
          Information collected from third parties or parties under common control (which includes
          our parent, subsidiaries, and other entities under common control with us - collectively
          "Related Parties") to validate and augment a user’s profile including a user’s email
          address, postal address, telephone number, device ID, or other user Information;
          Information collected from our internal center, customer support department and
          fulfillment vendors, including customer support inquiries, Reward-related claims and
          emails and, as needed, proof of identification and completion of Reward requirements;
          Information relating to a user’s interaction with telemarketing calls, SMS messaging, push
          notifications, commercial email, and other forms of communication; Information collected
          by third parties regarding users' interaction with our Site which may include the creation
          of a report and visual playback of the user responding to one or more survey questions and
          the interacting with the page that seeks to obtain a user's “prior express written
          consent” (as defined in the Telephone Consumer Protection Act (“TCPA”))(“TCPA Consent”) to
          receive telemarketing and text messages; and Information collected from Cookies (as
          defined below), including whether a user has previously visited the Website or has opened
          an email sent by one of our Marketing Partners.{' '}
        </p>

        <p>
          HOW WE COLLECT INFORMATION - SOURCES We collect Information from and about users (see
          below for additional descriptions of the methods of Information collection that are
          provided to California residents) from the following sources: Registration forms and
          surveys; Emails, telephone calls, USPS mail, and other contacts received from users as
          part of customer service or to claim Rewards; Publicly availably sources, such as Internet
          search engines; Our hosting servers, which collect Information from users through various
          technological means when users access and interact with the Website; Third parties
          (including service providers) who assist us in hosting the Websites, providing customer
          support, and fulfilling Rewards; Our Marketing Partners and third party publishers, who
          may (a) send emails, push notifications, or SMS messages or make telemarketing calls to
          users, (b) drive users to the Website, and/or (c) advertise goods and services on the
          Website; and Cookies (small text files placed on a user’s browser by a website such as the
          Website that track online behavior), web beacons (single-pixel GIF image files), and other
          technological means (collectively, “Cookies”). We may combine Information collected from
          various sources to augment and verify Information to improve the overall quality of the
          Website experience.{' '}
        </p>

        <p>
          HOW WE MAY USE INFORMATION We may use Information in many ways and for many purposes,
          including the following (see below for additional descriptions of Information uses that
          are provided to California residents): For Website operations, content improvements,
          testing, research, analysis, and product development; To verify whether users are entitled
          to participate in the Rewards Program and, if so, to fulfill Rewards; To provide users
          with customer service and respond to customer inquiries; To provide users with email,
          direct mail, and telemarketing messages concerning certain of our products and/or
          services, as well as third-party products and/or services, that we believe may be of
          interest to you; To provide users with email alerts, text/SMS message alerts, and other
          communications that users have requested or consented to receive or that we are required
          to send concerning users’ registrations, Rewards, our products or services, or other
          information; To carry out our obligations and enforce our rights arising from any
          contracts entered into between users and us, including for purposes of our Arbitration
          Agreement and any inquiry specific to express written consent under the Telemarketing
          Sales Rule (“TSR”) and/or the Telephone Consumer Protection Act (“TCPA”); To detect,
          investigate, and respond to any fraudulent or malicious activity or any other activity we
          determine is or may be a violation of our Terms and Conditions, this Policy, or existing
          laws, rules, and regulations; As necessary or appropriate to protect the rights, property,
          and/or safety of us and our Marketing Partners, service providers, and Related Parties; As
          described to you when collecting Information or as otherwise set forth in any applicable
          consumer protection, state or federal privacy, or data security laws; To maintain
          suppression or opt-out lists so that we won't contact users who have asked not to be
          contacted; To augment Information by enhancing it with other data sources provided by
          third parties and Related Parties to enable us, for example, to gain deeper insights into
          our users; and To respond to subpoenas, court orders, or legal process, or to establish or
          exercise our legal rights or defend against legal claims, or when we determine it is
          necessary to comply with applicable laws and/or regulations. We may share Information with
          applicable third party(ies) as necessary if we become the subject of bankruptcy
          proceedings, or in connection with the sale, assignment, merger, or other transfer of all
          or a portion of our business. If we are involved in any of these transactions, we will
          notify users via email or a Policy update on the Website regarding any material details
          pertaining to Information.{' '}
        </p>

        <p>
          HOW WE MAY DISCLOSE AND/OR SELL INFORMATION We may share and/or sell Information with our
          vendors, Marketing Partners, or other third party(ies). For example, we may disclose
          and/or sell information including: (see below for a description of the augmented rights
          that California residents have with respect to opting-out of the sale of their
          Information): With our vendors, service providers, and/or Marketing Partners in connection
          with marketing activities, including the delivery of email messaging, telemarketing calls,
          text/SMS messaging, and push notifications to users, provided that we have obtained TCPA
          Consent from such users to be contacted by telephone or text/SMS messaging; With our
          interest-based Marketing Partners to create anonymized sets of Information which are made
          available for use by third-party Marketing Partners for programmatic advertising purposes;
          With our related entities to augment and verify the Information we collect and to develop
          and enhance their products and services; With one or more vendors who provide independent
          verification of consent, including verification of consent for purposes of the TSR and
          TCPA; To enable third parties, including our Marketing Partners and data and list
          management partners, to market products and services to you; To enable customer service
          vendors to perform their respective tasks, including for fulfillment of Rewards; To enable
          our Information verification and fraud detection vendors to perform contracted-for
          services; and To submit users’ contact information to comment and petition campaigns run
          by governmental agencies, elected officials, or other parties where users have provided
          their express consent to endorse and be a part of the campaign including, in some
          instances, appending the user contact information with specific comment language. When we
          share and/or sell Information, we enter into agreements that describe the purpose for
          which the Information is shared/sold, restrict its use to the agreement’s specified
          purpose, and require that all Information remain confidential and be protected from
          unauthorized disclosure.{' '}
        </p>

        <p>
          MINORS AND NON-US RESIDENTS The Websites are designed to comply with the Children's Online
          Privacy Protection Act (“COPPA”). COPPA requires that website operators not knowingly
          collect PII from anyone under the age of 13 without prior verifiable parental consent. In
          compliance with COPPA, we do not knowingly collect or retain information from the Website
          from children under the age of 13 and may only collect a limited amount of Information
          from users who are between the ages of 13 and 18. The Website is intended for use only by
          U.S. residents and the Terms and Conditions limit the use of the Website to U.S. residents
          and instruct others not to utilize the Website.{' '}
        </p>

        <p>
          COOKIES, INTEREST-BASED ADVERTISING, AND THIRD-PARTY ANALYTICS Our website uses Cookies to
          determine whether users have previously visited the Website and to streamline the
          registration process so, for example, users don't have to re-enter their basic information
          when they return to the Website. We may also use Cookies to enhance a user's experience
          while on the Website. Some of our third-party Marketing Partners may place Cookies on the
          Website to track users’ online behavior while on the Website and after they leave the
          Website. These third-party Cookies may enable the delivery of interest-based
          advertisements (to find out more about Cookies, visit
          https://www.networkadvertising.org/understanding-online-advertising/glossary/) based on a
          user’s visit to the Website and/or other websites. In addition, our Marketing Partners use
          Information gathered in this manner to recognize users across different channels and
          platforms, including computers, mobile devices, and Smart TVs. Our Marketing Partners may
          use this information over time for advertising, analytics, attribution, and reporting
          purposes. For additional information about the health-related, political, and related
          custom data segments used for advertising, please click here. We may use Google Analytics
          and similar tools provided by our third-party partners to help analyze how users interact
          with the Website and to display customized ads and other content to our users during a
          current browsing session or in the future when the user is online. These analytics are
          performed by using the technological means described above to monitor a user’s
          interactions with the Website and do not involve the collection of any additional PII.
          Most browsers are initially set up to accept Cookies, but users can reset their Internet
          browsers to refuse all Cookies or to indicate when a Cookie is being sent or to refuse
          online tracking. To disable and reject certain Cookies, follow the instructions associated
          with your Internet browser. In general, when a "do-not track" setting is active, the
          user's browser notifies other websites that the user does not want their personal
          information and online behavior to be tracked and used, for example, for interest-based
          advertising. As required under some State laws, we are required to inform users that, as
          is the case with most websites, we do not honor or alter our behavior when a user to one
          of the Websites has activated the "do-not-track" setting on her/his browser. Even where
          you reject a Cookie, you may still use the Website but your ability to use certain
          features or offerings may be impaired. For example, if you return to the Website, you may
          have to re-enter Information you previously supplied to us. We may retain Cookie data
          indefinitely. Users may opt-out from interest-based advertising delivered to their web
          browser by companies who have elected to participate in one of the interest-based
          advertising opt-out mechanisms such as the Digital Adverting Alliance’s opt-out program
          located here or the National Advertising Institute’s opt-out program located here. Users
          may also choose to opt-out of interest-based advertising on their mobile devices. For more
          information, please click here. Please note that opting out of interest-based advertising
          does not mean you will no longer see advertising online. Rather, it means that the company
          or companies from which you opt-out will no longer show ads that have been tailored to
          your interests.{' '}
        </p>

        <p>
          OPTING-OUT FROM MARKETING Users may opt-out from receiving future contact from us. To
          opt-out from email marketing and to remove your anonymized data from programmatic
          advertising enabled by contacting customer service, click the “Unsubscribe” link on the
          bottom of every page or click here; Providing TCPA Consent is not required to qualify for
          a Reward. You may continue to use all other aspects of the Website, regardless of whether
          you elect to provide TCPA Consent. Users who elected to provide TCPA Consent but later
          wish to revoke it can opt out from future telemarketing calls by following prompts to stop
          individual telemarketing calls on recorded calls, by informing calling parties that they
          wish to be placed on their do not call list, or by contacting, as appropriate, the
          Marketing Partner(s) or our customer service team here. The Terms & Conditions also
          contain guidance for opting out from telemarketing; To revoke consent for, or opt-out
          from, SMS/text messaging, reply STOP to text/SMS messages received or contact customer
          service here; To opt-out of browser-based push notifications, disable the notifications at
          the time you receive the notification or disable notifications through your browser’s
          settings; and To-opt out of Cookies, interest-based advertising and/or third-party
          analytics, see the section above. In addition and as described below, residents of
          California (and, in certain circumstances, residents of Nevada) have the right to opt-out
          of the sale of their Information.{' '}
        </p>

        <p>
          ACCESSING, CORRECTING, AND DELETING YOUR INFORMATION We may use Information provided by
          users for the purposes disclosed in this Privacy Policy until a user requests that their
          Information be modified, deleted, or corrected. All users who provide Information to us
          may request that we modify, delete, and/or correct their Information by contacting us here
          (see below for a description of the augmented rights with respect to accessing and
          deleting Information that are provided to California residents). We will respond to user
          requests within 45 days. If you request deletion of your Information, please note that
          your Information will be suppressed from further use as described in this Policy. However,
          please remember that if we have shared your information with our third-party Marketing
          Partners, you will need to contact those Marketing Partners individually to have them
          suppress or delete your personal information. Note that we may retain and use your
          Information as necessary to comply with our policies including legal obligations, dispute
          resolution, and enforcement of our agreements. We ask individual users to identify
          themselves and the Information requested to be accessed, corrected, or deleted
          (suppressed) before processing such requests and, to the extent permitted by applicable
          law, we may decline to process requests that we are unable to verify, are unreasonably
          repetitive or systematic, require disproportionate technical effort, jeopardize the
          privacy of others, or would be extremely impractical (for instance, requests concerning
          Information residing on archive servers).{' '}
        </p>

        <p>
          HEALTH AND WELLNESS INFORMATION Our surveys may ask health and wellness-related questions.
          We may share your responses with our third-party Marketing Partners who advertise health
          and wellness-related products and services on the Website, or who otherwise promote health
          and wellness-related products or services. We may also use this Information to display
          offers for health and wellness-related products and services to you. With your consent, we
          may provide Information to these providers so they can contact you. If you do not wish to
          share health and wellness-related Information, do not answer the health and
          wellness-related survey questions and do not provide TCPA Consent.{' '}
        </p>

        <p>
          FINANCIAL INFORMATION Our surveys may ask questions related to your financial status. We
          may share your responses with our third-party Marketing Partners who advertise
          finance-related products and services on the Website, or who otherwise promote
          finance-related products or services. We may also use this Information to display offers
          for finance-related products and services to you. With your consent, we may provide this
          Information to providers so they can contact you. If you do not wish to share this
          Information, do not answer the finance-related survey questions and do not provide TCPA
          Consent.{' '}
        </p>

        <p>
          MEDICARE SUPPLEMENT PLANS DISCLOSURE Some of our Marketing Partners offer Medicare
          Supplement plans. Medicare Supplement plans are not connected with or endorsed by the U.S.
          Government or the Federal Medicare program. Our Marketing Partners have confirmed to us
          that all related optional marketing offers and Deals on the Website comply with applicable
          Federal civil rights laws and do not discriminate on the basis of race, color, national
          origin, age, disability, or sex. With your consent, we may provide information to Medicare
          Supplement providers so they can contact you. If you do not wish us to share your
          Information, do not provide consent to telemarketing.{' '}
        </p>

        <p>
          THIRD-PARTY SITES The Website contains links to other sites or services. When you link
          from the Website to a third-party site, for example, when you link from a survey question,
          optional marketing offer, or Deal advertisement to another site, you are leaving the
          Website and are subject to the privacy policies and terms and conditions of those sites –
          NOT this Privacy Policy. We are not responsible for the privacy practices, content, or
          security of such third-party sites.{' '}
        </p>

        <p>
          SECURITY OF YOUR INFORMATION We use commercially reasonable efforts to prevent
          unauthorized access, disclosure, or accidental loss or destruction of your Information.
          Access to your Information is strictly limited and we take reasonable measures to ensure
          that your Information is not accessible to the public. We restrict access to users’
          Information to only those persons who need access to perform or provide their job or
          service, both internally and with our third-party service providers. As part of the
          multistep \Reward claim process, you will be asked to verify your identification by
          scanning or uploading a clear photo of an unexpired, government-issued ID. The ID
          verification process is operated through our third-party vendor, Veratad. Submission of
          your ID via a secure process is encrypted end-to-end, and your information will only be
          used for purposes of ID verification. We do not otherwise generally collect sensitive
          Information such as medical information, health insurance information, data collected from
          an automated license plate recognition system, Social Security Number, and credit card
          information. If and when we do collect, store, and transmit sensitive information, that
          Information will be encrypted with advanced TLS (Transport Layer Security). While we have
          endeavored to create a secure and reliable Website for users, we are not able to fully
          eliminate security risks associated with Information. Given the nature of the Internet,
          your Information passes through entities that we are unable to control. Therefore, we
          cannot guarantee that our security measures or those of third parties who access or
          transmit your Information will prevent your Information from being improperly accessed,
          stolen, or altered. In compliance with applicable federal and state laws, we shall notify
          you and any applicable regulatory agencies if we learn of an information security breach
          of your Information. You will be notified via email in the event of such a breach. Please
          be advised that notice may be delayed in order to address the needs of law enforcement,
          determine the scope of network damage, and to engage in remedial measures.{' '}
        </p>

        <p>
          CALIFORNIA NOTICE REGARDING CATEGORIES, SOURCES AND BUSINESS AND COMMERCIAL USES OF
          INFORMATION The following chart contains the categories of information, as enumerated in
          CCPA Sec. 1798.140(o), of Information we collect, use, disclose and sell, the sources of
          the Information and our business and commercial uses of such Information: Category
          Category Name/Description 1. Sources 2. Business Use 3. Commercial Use A Identifiers -
          name, postal, email and IP address, telephone number User provided on registration,
          customer service inquiries and claim forms; related and 3rd party sources to verify and/or
          augment user supplied information Operational purposes including determining survey
          questions, optional offers and deals to be displayed; customer service; fraud & security
          incidence detection; pre-population of user forms; website improvement Sold to third
          parties for marketing purposes; shared with third parties to determine deals to be offered
          and to pre-populate order forms B Personal information categories listed in the California
          Customer Records statute – Identifiers and education, employment medical and health
          information As above, plus user responses to survey questions Same as 2. A Same as 3. A
          and to target sales of Information to third parties C Protected classification
          characteristics under California or federal law - Age, marital status, medical condition,
          physical or mental disability, sex and related medical conditions Same as 1. B Same as 2.
          A Same as 3. B D Commercial information - products or services purchased, obtained, or
          considered, or other purchasing data User provided customer service inquires and claim
          forms, from 3rd party advertisers and system collected website activity logs Same as 2. A
          and to determine entitlement to rewards and amounts owed by advertisers and data
          purchasers Determine amounts owed by advertisers and data buyers and performance of data
          campaigns E Internet or similar network activity -browsing and search history, information
          on a consumer's interaction with a website, application, or advertisement System collected
          activity logs and cookies Same as 2. A and to determine (i) whether user is a repeat
          visitor; (ii) performance of and amounts owed to paid media suppliers; and (iii) support
          proof of user consent for email marketing and TCPA purposes Same as 3. D F Inferences
          drawn from other personal information - profile reflecting a person's preferences,
          characteristics, psychological trends and attitudes Same as 1. B and system algorithms
          Same as 2. A None{' '}
        </p>

        <p>
          RIGHTS OF CALIFORNIA USERS WITH REGARD TO THEIR INFORMATION California users have several
          rights regarding the collection, use, disclosure, and sale of their information under the
          CCPA. One of these rights, notice of collection of Information, is described above; the
          balance of these rights are described below and include the following rights: Disclosure
          of information collected and sold Opt-out from the sale of Information Deletion of
          Information Access and portability of Information collected Non-discrimination{' '}
        </p>

        <p>
          Exercising Your Rights, Verification, and Timing. To exercise your CCPA rights, click on
          the “Do Not Sell MyPersonal Info” link on the bottom of the Website page where we collect
          Information, or send an email. To prevent an unauthorized
          third party from accessing or making decisions regarding a user’s Information, we verify
          all requests to exercise these rights (a verifiable consumer request). We do not charge a
          fee to process or respond to a verifiable consumer request unless it is excessive,
          repetitive, unfounded, or abusive. In general, we will verify a request by matching the
          identifying information supplied by the user with the Information we already have for the
          user. If we cannot verify the user using the foregoing method, we may request additional
          information, which we will not retain or use for any other purpose. For all verifiable
          consumer requests, we strive to respond within forty-five (45) days of receipt. If we
          require additional time, which can be up to ninety (90) days in the aggregate, we will
          provide written notice. Any disclosures we provide will cover only the 12-month period
          preceding the verifiable consumer request's receipt and will be delivered electronically
          in a secure fashion. If, for some reason, we cannot fulfill or comply with your request,
          our response will explain that decision.{' '}
        </p>

        <p>
          Disclosure of Information Collected and Sold. While users have the right to request
          different levels of disclosure regarding the collection, use, disclosure, and sale of
          Information, we have elected to treat all requests from California residents as an
          exercise of expanded disclosure requests by businesses that sell Information. Once we
          receive and confirm your verifiable consumer request, we will disclose to you for the 12
          months prior to your request: The categories and sources of Information collected; Our
          business and commercial purposes for collecting and selling that Information; The
          categories of third parties with whom we shared that Information; The specific pieces of
          Information collected; If we sold or disclosed your personal information for a business
          purpose, we will disclose: For sales - the Information categories for each category sold
          and the categories of third parties to whom the Information was sold; and For disclosures
          - the Information categories for each category disclosed and the categories of third
          parties to whom the Information was disclosed.{' '}
        </p>

        <p>
          Right to Opt-Out. Users have the right to opt-out from the sale of their Information. We
          provide a “Do Not Sell My Personal Info” link on each Website page where we collect
          Information that links to an online request form. You can access the form here or you can
          send an email and elect to opt-out from the sale of your
          Information. We reserve the right to verify all opt-out requests using the methods
          described above. While our agreements with third parties to whom we sell or disclose
          Information prohibit the further sale or use beyond the scope of our agreement with them,
          if a California user opts-out, we will notify all third parties to whom we have sold or
          disclosed the California user’s Information within the previous 90 days and remind them
          not to further sell the Information. We will not ask a California user who has opted out
          to re-opt-in for one year after the California user opts-out. If the user requests a
          transaction with us that requires the sale of their Information, we will inform them that
          they have opted out and if they want to proceed with the transaction, we will offer the
          opportunity to opt-in by completing a two-step process.{' '}
        </p>

        <p>
          Deletion of Information. You have the right to request that we delete any of your
          Information that we collected from you, subject to certain exceptions. Once we receive and
          confirm your verifiable consumer request using the process set forth above, we will delete
          or deidentify (and direct our service providers, vendors, and other applicable third
          parties to delete) your Information from our system other than from our archive servers
          (which we maintain for compliance purposes), unless there is an applicable exception. We
          may decline to process your deletion request if the Information is necessary for us or our
          service providers, vendors, or other applicable third parties for a number of reasons. For
          example, we may decline your deletion request if the Information is required to: Complete
          the transaction for which we collected the Information or provide the goods or services
          you requested; Detect security incidents, protect, investigate and respond to malicious,
          deceptive, fraudulent, or illegal activity, or prosecute those responsible for such
          activities; Identify and correct errors that negatively impact intended functionality; and
          Comply with a legal obligation, including Federal and/or State tax liability and
          reporting. If we do not comply with a deletion request, we will provide the grounds for
          our denial.{' '}
        </p>

        <p>
          Access and Portability. California users have the right to access the Information we have
          collected about them in the previous twelve (12) months. Upon receipt of verified consumer
          request, we will provide the information in a readily usable format. You cannot request
          access more than twice in any 12-month period.{' '}
        </p>

        <p>
          Nondiscrimination. We are committed not to discriminate against California users who
          exercise their CCPA rights. As such, unless otherwise permitted by the CCPA, we will not
          deny, charge you different prices or rates, or provide a different quality level of goods
          or services, including through granting discounts or other benefits, or imposing
          penalties, and/or suggest that you may receive a different price, rate, or quality level
          of goods or services. While we do not provide financial incentives for users who do not
          exercise their CCPA rights, please note that some of the functionality of the Website may
          be unavailable if consumers exercise their rights to have their personal information
          deleted.
        </p>

        <p>
          Authorized Agent. The CCPA enables California users to appoint an authorized agent to act
          on their behalf to submit disclosure requests and or deletion requests under the CCPA. The
          authorized agent must register with the California Secretary of State. We will honor a
          request from an authorized agent provided you provide written authorization to the
          authorized agent to act on your behalf and we can verify your identity and the agent
          submits proof of authorization.{' '}
        </p>

        <p>
          CCPA Metrics. We are required to compile certain annual metrics regarding the CPPA
          including the number of disclosure, deletion and opt-out requests we receive, the median
          number of days it takes us to respond, and how many of each were denied. The metrics for
          the last calendar year are displayed here. Please note that the information outlined is
          compiled from various entities, which include our parent, subsidiaries, and other entities
          under common control with us.{' '}
        </p>

        <p>
          CALIFORNIA ONLINE PRIVACY PROTECTION ACT Shine the Light Rights. In addition to the
          CCPA-mandated disclosures described above, the California Online Privacy Protect Act
          (“CalOPPA”) contains a Shine the Light section that permits users who are California
          residents to request information regarding our disclosure of their Information to third
          parties, including the categories of Information shared and a list of the names and
          addresses of third parties with whom the Information was shared. If you are a California
          resident and would like to exercise your rights under the Shine the Light provision,
          please submit a request by sending an email. In your request,
          please specify that you want to exercise your rights to disclosure under the Shine the
          Light provision please specify the Website that you visited and the email address you
          registered with. Please allow thirty (30) days for a response.{' '}
        </p>

        <p>
          STATE SPECIFIC PRIVACY RIGHTS To exercise your privacy rights, please submit your request
          by clicking on the link “Do Not Sell MyPersonal Info” or send an email . In your request, please specify that you want to exercise your
          rights and specify the Website that you visited and the email address you registered with.
          We may contact you to ask you for further information to help you exercise your rights. We
          will try to respond to requests within one month and we will let you know if we need to
          take longer, for example, because your request is complicated.{' '}
        </p>

        <p>
          CHANGES TO THIS POLICY We reserve the right to revise and update this Policy at any time.
          If we make material changes to this Policy, we will post the updated Policy on the
          Website. Any such revisions will be effective thirty (30) days after posting to the
          Website and will apply to all Information collected by us both before and following the
          effective date. If we expand the way we use Information, we will notify users by: (a)
          sending the modified Policy to our users via email or (b) any other reasonable means
          acceptable under applicable state and federal law. You will have a choice as to whether we
          use your Information in this different manner and we will only use your Information in
          this way where you opt-in to such use. Users should periodically access this Policy to
          review our current policies regarding the collection and use of Information.
        </p>

        <p>
          QUESTIONS, SUGGESTIONS, OR TO CONTACT US If you have any questions, comments, complaints,
          or suggestions regarding this Policy or the Website, please contact us
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

import React from 'react';
import './Terms.css';

const TermsandConditions = () => {
  return (
    <div>
      Rewards & Samples, LLC Websites
      <h3>Terms&Conditions</h3>
      <div className="terms-main">
        <p>
          RewardsandSamples.com (“we,” “us,” or “our”) operates the http://www.rewardsandsamples.com
          website and other associated websites (collectively, the “Sites”), where we provide you
          with links to sites that offer the best free samples of brand name products including
          household goods and health and beauty aids (“Free Sample Links”) by providing us with
          certain information. By accessing and using the Sites, you agree to and accept these Terms
          & Conditions, as well as our Privacy Policy and any additional terms, conditions and
          policies published on our Sites (collectively, the “Agreement”). If you do not agree to
          and accept the terms of the Agreement in their entirety, you do not have permission to
          visit and/or use any of the Sites.{' '}
        </p>

        <p>
          Mandatory Arbitration. These Terms & Conditions contain a mandatory arbitration provision,
          as detailed below, that requires you to arbitrate, individually, all disputes or claims
          that you may have with us, our parent, related parties, advertiser clients and marketing
          partners (collectively, “Marketing Partners”) who are third-party beneficiaries of the
          mandatory arbitration provision. Thus, for example, if you provide prior express written
          consent to be contacted via telemarketing or SMS/text messaging, any claims that you may
          have regarding any telemarketing or SMS/text messages that you receive are subject to the
          mandatory arbitration provision. The mandatory arbitration provision also waives your
          right to participate in a class action or multi-party arbitration. There are two narrow
          exceptions to the mandatory arbitration provision: (1) you may opt-out by providing
          written notice of your decision to do so within thirty (30) days of the date that you
          first register on one of our Sites; and (2) you may opt-out by filing a claim in Small
          Claims court provided the requirements described below are met.{' '}
        </p>

        <p>
          How the Site Works. We comb the Internet for links to sites that offer the best free
          samples of brand name products including household goods and health and beauty aids (“Free
          Sample Links”). We are able to provide the Free Sample Links at no charge to our consumers
          by them providing us with certain information about themselves that enables us to identify
          products and services that may interest them and sharing this information with our
          marketing partners. Our marketing partners compensate us for connecting them with
          interested consumers. To qualify to get access to the Free Sample Links, you’ll need to
          enter a valid e-mail address as well as your name, address, and date of birth. You may
          also need to respond to a series of survey questions. You may also be asked to confirm
          that all of your information is accurate and agree to be contacted by our marketing
          partners, typically via email. In some cases, we will ask you to consent to be called
          and/or texted by our marketing partners. You do not need to consent to be called or texted
          to purchase the advertised item or to gain access to the Free Sample Links. Then, you will
          be presented with Free Sample Links which enable you to get samples of cosmetics,
          household items and other goods or services from your favorite brands. If you click on a
          particular link, you’ll usually be directed to a website operated by the provider of the
          free sample who may ask for your contact information so they can send you the free sample.
          The products and goods and services offered in the Free Sample Links may change from time
          to time so please stop back and see what’s available.{' '}
        </p>

        <p>
          Telemarketing and Text Messages. Where you provide “prior express written consent” within
          the meaning of the Telephone Consumer Protection Act (“TCPA”), you consent to receive
          telephone calls, including artificial voice calls, pre-recorded messages and/or calls
          delivered via automated technology and text and SMS messages to the telephone number(s)
          that you provided from us and the marketing partners listed in and hyperlinked to the
          consent. You are not required to provide this consent to obtain access to the Free Sample
          Links and other offers on the Sites and your consent simply allows us to contact you via
          these means. If you provide consent, CAC a related party of ours and any of our other
          related parties ("Related Party") or marketing artners named in the consent may send you
          SMS messages from their short codes or long codes. Our short codes are 53294 and 411411;
          we may acquire additional short codes. Message Frequency Varies, maximum 15 messages per
          month. Message and data rates may apply. Text STOP to opt-out from future messages and
          HELP for help. The mobile carriers are not liable for delayed or undelivered messages.{' '}
        </p>

        <p>
          No Representations or Warranties. The Free Sample Links and other information, samples and
          services provided on the Site and the content, information, documents, graphics and images
          displayed on the Site are provided by us and our marketing partners, sample providers,
          sample manufacturers and other third parties and could include inaccuracies, typographical
          errors or other errors. We make no commitment to update or correct any errors on the Site.
          You also understand and agree that the Site may, at times, be inaccessible or inoperable
          for any reason, including: (a) equipment or communications malfunctions; (b) periodic
          maintenance, repairs or administrative reviews which we may undertake from time-to-time;
          or (c) causes beyond our control or which are not foreseeable by us. We will not be
          responsible or liable in any way to you or anyone else due to or as a result of any such
          inaccessibility or inoperability. OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS &
          CONDITIONS, NEITHER WE NOR ANY OF OUR MARKETING PARTNERS, SAMPLE PROVIDERS OR THE SAMPLE
          MANUFACTURERS MAKE ANY SPECIFIC PROMISES ABOUT THE SITE, THE FREE SAMPLE LINKS OR OTHER
          GOODS OR SERVICES PROVIDED THEREIN. WE PROVIDE THE SITE AND OTHER SERVICES “AS IS.” SOME
          JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED WARRANTY OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. TO THE EXTENT
          PERMITTED BY LAW, WE EXCLUDE ALL WARRANTIES.{' '}
        </p>

        <p>
          Privacy Policy; Confidentiality. Our Privacy Policy contains terms and conditions that
          govern our collection and use of the information you provide us or that we obtain through
          your use of our Site and our respective rights relative to that information. In general,
          we share information you provide us with our marketing partners. Please review our Privacy
          Policy before you use our Site. Your use of the Site indicates your agreement to our
          Privacy Policy. As provided for in the Privacy Policy, we cannot guarantee your
          confidential use of the Site. We will not be responsible for any harm that you or any
          person may suffer as a result of a breach of confidentiality from your use of the Site.{' '}
        </p>

        <p>
          Eligibility. The Site is intended for use by United States residents who are over 13 years
          of age. We do not represent that all content, materials and services on our Site are
          appropriate or available for use in geographic locations outside the United States, and
          accessing the Site from certain locations may be illegal and prohibited. You agree not to
          access the Site or any content, materials and services on the Site where prohibited by
          law. We are not responsible for your compliance with local laws or other applicable laws.{' '}
        </p>

        <p>
          Equipment. You are solely responsible for providing and maintaining all hardware,
          software, electrical and other physical requirements for your use of the Site, including
          telecommunications and internet access connections and links, web browsers or other
          equipment, compatibility, and all other programs or services required to access and use
          the Site.{' '}
        </p>

        <p>
          Proprietary Rights. The Site including the Free Sample Links are made available for your
          personal, non-commercial use only and you agree not to copy, duplicate, display, transmit,
          distribute, modify, reverse engineer or prepare derivative works of all or any portion of
          the Site for any purpose. Portions of the Site are proprietary to us and are protected by
          intellectual property laws and treaties, including copyright, trademark, service mark,
          trade secret and/or patent laws and we reserve all of our rights. Some of the services and
          content on the Site are provided by third parties and is proprietary to these third
          parties. The names of companies and products that we do not own and that are mentioned on
          the Site may be the trademarks of their respective owners. Any use of the protected
          services and/or content belonging to us or third parties without the express written
          permission of the owner thereof is strictly prohibited. You may not use the Site to sell a
          product or service, or to increase traffic to your website for commercial reasons, such as
          advertising sales. You may not take the search results and reformat and display them, or
          mirror the Site home page or search results pages on your website.{' '}
        </p>

        <p>
          Site Links and Third-Party Sites. The Site contains the Free Sample Links and other links
          to third-party websites which are independent of the Site. We make no representation or
          warranty as to the accuracy, value, integrity, completeness or authenticity of the
          information or opinions contained in the Free Sample Links or any other linked website. We
          do not endorse any website contained within the Free Sample Links or any other linked
          website, or the products or services described therein and are not responsible for and
          disclaim all liability for these third-party websites. You should review the terms and
          conditions and privacy policies applicable to these third-party websites. Furthermore,
          these links may lead to websites or links that contain offensive and objectionable content
          or which may contain dangerous computer viruses. You assume, and we hereby disclaim, all
          responsibility for any of the content on these websites or for any damage sustained by
          users of these websites.{' '}
        </p>

        <p>
          Fraudulent Activity Policy. We strictly prohibit user fraud and abuse relating to access
          to and use of the Site. In accessing the Site, or any other activities, products or
          services offered by or through the Site, you represent and warrant that: (a) all
          information you supply is complete and accurate, (b) you are not acting in violation of
          any applicable laws, rule or regulations or of these Terms & Conditions, and (c) you will
          not circumvent or attempt to circumvent any provision these Terms & Conditions or any
          security feature on the Site or engage in any activity that interrupts or attempts to
          interrupt the Site’s operation. We may take action, including disabling a user’s account,
          if we deem that a user is exhibiting unusual patterns of behavior and/or randomly and/or
          excessively clicking on a link or links after performing a search.{' '}
        </p>

        <p>
          Choice of Law. You agree that these Terms & Conditions constitute the agreement between
          you and us and shall be construed and governed in accordance with the laws of the State of
          New York, regardless of the conflicts of law provisions of the jurisdiction where you live
          or in any other jurisdiction.
        </p>

        <p>
          Arbitration/Dispute Resolution. You agree to arbitrate any and all claims arising out of
          or related to the Agreement. If you have a dispute concerning any aspect of the Agreement,
          including without limitation, your participation in a Promotion, entitlement to an
          Incentive or a telemarketing call or SMS/text message that you received from us or a
          Marketing Partner, you should first contact customer support on the Site or complete a
          customer support ticket. We will attempt to resolve the matter to your satisfaction within
          thirty (30) days of our receipt of a customer support ticket. We may choose to provide you
          with a final written settlement offer during this process. If we provide you with a final
          written settlement offer and you don't accept it, if we can't otherwise satisfactorily
          resolve your dispute, or if you choose to skip this step, you must submit your dispute for
          resolution by arbitration before the American Arbitration Association ("AAA") in the
          county where you live by filing a separate Demand for Arbitration online by following the
          instructions at https://apps.adr.org/webfile/. An AAA arbitrator will have exclusive
          authority to resolve any dispute or claims that you may have with us—and our Marketing
          Partners who are third-party beneficiaries of the mandatory arbitration provision—arising
          out of or related to the Agreement. Matters subject to mandatory arbitration include,
          without limitation, whether this Arbitration/Dispute Resolution provision applies to your
          particular claim or dispute, and any claim that all or any part of the Agreement,
          including this provision, are/is unenforceable. If the claim is against us, you will need
          our mailing address to file online. To obtain our mailing address, contact us. 
          Either party may submit the dispute for resolution by arbitration at a location
          reasonably convenient to both parties. If either party files for arbitration, it will be
          conducted in accordance with the then current AAA Consumer Arbitration Rules. If you
          proceed to arbitration against us, you will pay all AAA filing fees and we will pay for
          all administration and arbitrator fees unless the arbitrator determines that your claim is
          frivolous or brought for an improper purpose (as measured by the standards set forth in
          Federal Rule of Civil Procedure 11(b). For claims brought by you of Ten Thousand Dollars
          ($10,000.00) or less, you can choose whether the arbitration proceeds in person, by
          telephone or based only on submissions. The arbitrator may award any form of individual or
          equitable relief, including injunctive relief. Any award will be final and conclusive to
          the parties and may be entered in any court of competent jurisdiction. If you initiate
          arbitration against us and the arbitrator awards you relief that is greater than our final
          written settlement offer made before an arbitrator was selected, then we will pay you a
          minimum recovery of Five Hundred Dollars ($500.00), plus we will reimburse any reasonable
          expenses incurred by your attorney, if any, including fees reasonably accrued for
          investigating, preparing and pursuing the claim in arbitration. Although under some laws
          we may have a right to an award of attorneys' fees and expenses if we prevail in
          arbitration, we agree that we will not seek such an award from you. You and your attorneys
          are not required to keep the results of the arbitration confidential. You agree to the
          entry of injunctive relief to stop such a lawsuit or to remove you as a participant in
          such a suit.{' '}
        </p>

        <p>
          Class Action Waiver. The arbitration provision contained in these Terms & Conditions does
          not constitute a waiver of any of your rights and remedies to pursue a claim individually
          and not as a class action in binding arbitration as provided above. This provision
          preventing you from bringing, joining or participating in class action lawsuits is an
          independent agreement.{' '}
        </p>

        <p>
          Exceptions to Mandatory Arbitration. There are two narrow exceptions to mandatory
          arbitration. First, you may opt-out of the Arbitration/Dispute Resolution provision by
          providing written notice of your decision within thirty (30) days of the date that you
          first register on the Site. To obtain our mailing address, contact us.
          Second, you may choose to pursue your dispute or claim in Small Claims Court rather than
          by arbitration but only if your dispute or claim qualifies for Small Claims Court in a
          location where jurisdiction and venue over both you and rewardsandsamples.com is proper.
          Other than these two exceptions, you must arbitrate any claims as provided above.{' '}
        </p>

        <p>
          YOU ACKNOWLEDGE AND AGREE THAT, VIA YOUR ACCEPTANCE OF THESE DISPUTE RESOLUTION
          PROVISIONS, YOU WAIVE ANY RIGHT TO A JURY TRIAL, AS WELL AS YOUR RIGHT TO BRING, JOIN OR
          PARTICIPATE AS A PLAINTIFF OR A CLASS MEMBER IN A CLASS ACTION SUIT OR MULTI-PARTY
          ARBITRATION BROUGHT AGAINST US, OUR MARKETING PARTNERS OR ANY SERVICE PROVIDER USED BY US
          TO PROVIDE THE SUBJECT SERVICE.{' '}
        </p>

        <p>
          LIMITATION OF LIABILITY. WHEN PERMITTED BY LAW, WE AND OUR MARKETING PARTNERS, SAMPLE
          PROVIDERS AND SAMPLE MANUFACTURERS, WILL NOT BE RESPONSIBLE FOR LOST PROFITS, REVENUES, OR
          DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE
          DAMAGES. TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF US, AND OUR MARKETING
          PARTNERS, SAMPLE PROVIDERS AND SAMPLE MANUFACTURERS, FOR ANY CLAIMS UNDER THESE TERMS &
          CONDITIONS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO
          USE THE SITE AND THE SERVICES. IN ALL CASES, WE AND OUR MARKETING PARTNERS, SAMPLE
          PROVIDERS AND SAMPLE MANUFACTURERS, WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT
          REASONABLY FORESEEABLE.
        </p>

        <p>
          Indemnification. You agree to indemnify, hold harmless and defend us, our shareholders,
          directors, employees and agents from and against any action, cause, claim, damage, debt,
          demand or liability, including reasonable costs and attorney’s fees, asserted by any
          person or entity, arising out of or relating to: (a) these Terms & Conditions and/or any
          breach or alleged or threatened breach by you; (b) your use of the Site or any search
          results or other content or materials or services transmitted or received by you; (c) any
          unacceptable or objectionable use of the Site by you; or (d) any negligent or willful
          misconduct by you.{' '}
        </p>

        <p>
          Integration and Conflicting Terms; Severability. These Terms & Conditions and the Privacy
          Policy as referenced herein, constitute the complete and exclusive agreement between you
          and us with respect to use of the Site and supersedes any and all prior or contemporaneous
          communications, representations, statements, agreements and understandings, whether in
          oral, written or electronic form, between you and us concerning the use of the Site. These
          Terms & Conditions and the Privacy Policy shall be construed as consistent with each other
          whenever possible, but if such construction is unreasonable due to conflicting terms, the
          terms of the terms of the Privacy Policy shall control over the Terms & Conditions. Any
          provision of these Terms & Conditions which is determined by a court of competent
          jurisdiction to be unenforceable in any jurisdiction shall be severable from these Terms &
          Conditions in that jurisdiction without in any way invalidating the remaining provisions
          of these Terms & Conditions. The unenforceability of any provision in a given jurisdiction
          shall not make that provision unenforceable in any other jurisdiction.
        </p>
      </div>
    </div>
  );
};

export default TermsandConditions;

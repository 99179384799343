import React, { useEffect, useState } from 'react';
import './Questions.css';
import Loader from '../Loader/Loader';

const Questions = ({ questionsAnswered, setQuestionAnswered }) => {
  // Questions: 1 Do you like "Brand"? Do you use "Brand/Product" - yes/no
  // || Do you shop at "Walmart/Sephora etc"
  // Questions: 2 How do you plan to ... use/spend .. Keep it/ Give to a Friend
  // Question 3: About how many times do you go shopping per week? - <3; 3-7; 7-10; 10+
  const [loading, setLoading] = useState(null);
  const [pageName, setPageName] = useState('cash100');
  const [quest1, setQuest1] = useState('Do you like to Shop Online?');
  const [quest2, setQuest2] = useState('How would you use your voucher?');

  useEffect(() => {
    setPageName(window.location.pathname.split('/')[2]);
    const pageN = window.location.pathname.split('/')[2];
    console.log(window.location.pathname.split('/')[2]);

    if (pageN === 'cash100') {
      setQuest1('Do you use Cash?');
      setQuest2('How do you plan to use your $100 Cash?');
    } else if (pageN === 'cash250') {
      setQuest1('Do you use Cash?');
      setQuest2('How do you plan to use your $250 Cash?');
    } else if (pageN === 'cash500') {
      setQuest1('Do you use Cash?');
      setQuest2('How do you plan to use your $500 Cash?');
    } else if (pageN === 'cash750') {
      setQuest1('Do you use Cash?');
      setQuest2('How do you plan to use your $750 Cash?');
    } else if (pageN === 'walmart100') {
      setQuest1('Do you shop at Walmart?');
      setQuest2('How do you plan to use your $100 Walmart Gift Card?');
    } else if (pageN === 'walmart500') {
      setQuest1('Do you shop at Walmart?');
      setQuest2('How do you plan to use your $500 Walmart Gift Card?');
    } else if (pageN === 'walmart750') {
      setQuest1('Do you shop at Walmart?');
      setQuest2('How do you plan to use your $750 Walmart Gift Card?');
    } else if (pageN === 'amazon100') {
      setQuest1('Do you shop from Amazon?');
      setQuest2('How do you plan to use your $100 Amazon Gift Card?');
    } else if (pageN === 'chipotle100') {
      setQuest1('Do you enjoy Chipotle?');
      setQuest2('How do you plan to use your $100 Chipotle Gift Card?');
    } else if (pageN === 'burgerking150') {
      setQuest1('Do you enjoy Burger King?');
      setQuest2('How do you plan to use your $150 Burger King Gift Card?');
    } else if (pageN === 'sephora750') {
      setQuest1('Do you shop at Sephora?');
      setQuest2('How do you plan to use your $750 Sephora Gift Card?');
    } else if (pageN === 'pizzahut1000') {
      setQuest1('Do you enjoy Pizza Hut?');
      setQuest2('How do you plan to use your $1000 Pizza Hut Gift Card?');
    }
  }, []);

  const Question1 = () => {
    return (
      <div>
        <h2 className="q-head">{quest1}</h2>
      <br/>
        <button className="question-answer-btn" onClick={() => setQuestionAnswered([1, 1])}>
          Yes
        </button>
        <button className="question-answer-btn" onClick={() => setQuestionAnswered([1, 1])}>
          No
        </button>
      </div>
    );
  };

  const Question2 = () => {
    return (
      <div>
        <h2 className="q-head">{quest2}</h2>

        <button className="question-answer-btn" onClick={() => setQuestionAnswered([1, 1, 1])}>
          Keep It
        </button>
        <button className="question-answer-btn" onClick={() => setQuestionAnswered([1, 1, 1])}>
          Give it to a Friend
        </button>
      </div>
    );
  };

  const handleQuestion3 = () => {
    setLoading(true);

    const doLoading = setTimeout(() => {
      setQuestionAnswered([1, 1, 1, 1]);
    }, 400);
  };

  const Question3 = () => {
    return (
      <div>
        <h2 className="q-head">How many times do you shop per week?</h2>

        <button className="question-answer-btn" onClick={() => handleQuestion3()}>
          1-3
        </button>
        <button className="question-answer-btn" onClick={() => handleQuestion3()}>
          3-6
        </button>
        <button className="question-answer-btn" onClick={() => handleQuestion3()}>
          7-10
        </button>
        <button className="question-answer-btn" onClick={() => handleQuestion3()}>
          10+
        </button>
      </div>
    );
  };

  return (
    <div className="questions-div">
      <div className="questions-form-container">
        {loading ? (
          <Loader width="13em" height="9em" />
        ) : (
          <div>
            <small id="question-caption">Answer question {questionsAnswered.length} of 3:</small>
            {Number(questionsAnswered.length) === 1 ? Question1() : <div></div>}
            {Number(questionsAnswered.length) === 2 ? Question2() : <div></div>}
            {Number(questionsAnswered.length) === 3 ? Question3() : <div></div>}
          </div>
        )}
      </div>
    </div>
  );
};

export default Questions;

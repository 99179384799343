import React, { useState } from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import PrivacyPolicy from '../../Terms&Conditions/PrivacyPolicy';

const Footer = ({ pageType = 'rewards' }) => {

  return (
    <div className="sr-footer">
      <div>
        COMPLETE 25 DEALS TO CLAIM A $1000 INCENTIVE OR COMPLETE FEWER TO CLAIM LOWER VALUE
        INCENTIVES. AVAILABLE VIA ACH, CASH APP®, PAYPAL®, AND GIFT CARDS.
      </div>
      <div>
        *UPON COMPLETION OF PURCHASE & PROGRAM REQUIREMENTS. Trade names or rights associated with
        all brands on this site are the property of their respective owners and are not affiliated
        with this promotion.
      </div>
      <div>
        {' '}
        <Link to={() => '/privacypolicy'} target="_blank" rel="noopener noreferrer">
          {' '}
          Privacy Policy
        </Link>{' '}
      </div>
    </div>
  );
};

export default Footer;
